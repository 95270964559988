<template>
  <!-- <HeaderComponent title="New Game Day" subtitle="Your Ultimate Game Release Calendar" bg-image=""/> -->
  <HeaderComponent :title="'New Game Day'" :subtitle="'Your Ultimate Game Release Calendar'" :bg-image="bgImage" />
  <main class="main">
    <section class="section">
      <div class="container padding-global-margins padding-top-huge padding-bottom-xlarge">
        <div class="flex-row add-negative-gap-y padding-bottom-medium">
          <div class="_12-columns add-gap-y padding-bottom-small">
            <h3>Platforms</h3>
          </div>
          <div
            class="auto-column add-gap-y platform-checkbox-wrapper"
            v-for="platform in platformList"
            :key="platform.id"
          >
            <input :id="platform.id" type="checkbox" :value="platform.id" v-model="platformFilters[platform.id]" />
            <div>
              <span>{{ platform.name }}</span>
            </div>
          </div>
        </div>

        <div class="flex-row add-negative-gap-y">
          <div class="_12-columns add-gap-y padding-bottom-small">
            <div ref="genreTitleWrapper" data-w-id="a9d4d374-cd6f-d63b-3299-fbb868112a06" class="genre-title-wrapper">
              <h3>Genres</h3>
              <div
                ref="rotateIcon"
                data-w-id="8693f5e3-237c-03bc-b912-1cae0bd55ae0"
                style="
                  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg)
                    skew(0, 0);
                  -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0);
                  -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0);
                  transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0);
                "
                class="w-embed genre-open-icon"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 14 12">
                  <path d="M2 4l5 5 5-5" fill="none" stroke="white" stroke-width="2"></path>
                </svg>
              </div>
            </div>
          </div>
          <div ref="platformTagsWrapper" style="height: 0px; opacity: 0" class="platform-tags-wrapper">
            <div class="auto-column add-gap-y platform-checkbox-wrapper" v-for="genre in genreList" :key="genre.id">
              <input :id="genre.id" type="checkbox" :value="genre.id" v-model="genreFilters[genre.id]" />
              <div>
                <span>{{ genre.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <aside id="calendar-wrapper" class="container padding-global-margins">
        <div class="calendar-container">
          <!-- <div v-if="isLoading" class="loading-indicator">
          <div class="spinner"></div>
          <p>Loading calendar events...</p>
        </div> -->
          <FullCalendar ref="fullCalendar" :options="calendarOptions" />
          <div v-if="isLoading" class="loading-overlay">
            <!-- Loading spinner or message -->
            <div class="spinner"></div>
            <p>Loading calendar events...</p>
          </div>
        </div>
      </aside>
    </section>
    <section>
      <div class="container padding-global-margins padding-top-huge padding-bottom-xlarge">
        <!--  -->
        <!--  -->
        <!--  -->
        <div class="flex-row padding-bottom-xlarge">
          <div class="_6-columns _12-mobile-landscape-columns padding-bottom-small">
            <h2 class="padding-bottom-xsmall">Sign up for the monthly game release Newsletter!</h2>
            <form class="form" id="newsletter-form" @submit.prevent="submitNewsletter">
              <div class="display-horizontal-flex padding-bottom-xsmall">
                <input class="text-field" type="email" v-model="newsletterEmail" placeholder="Your email" required />
                <button
                  class="submit-button"
                  :class="{ disabled: !acceptTerms }"
                  type="submit"
                  :disabled="!acceptTerms"
                >
                  {{ buttonLabel }}
                </button>
              </div>
              <label class="checkbox-field">
                <input class="checkbox" type="checkbox" id="acceptTerms" v-model="acceptTerms" required />
                <span class="checkbox-label padding-left-xsmall" for="acceptTerms"
                  >I accept the
                  <a href="/terms-and-conditions" target="_blank" class="link">terms and conditions</a></span
                >
              </label>
            </form>
            <transition name="fade">
              <div v-if="message" :class="messageType === 'error' ? 'error-box' : 'success-box'" class="message-box">
                {{ message }}
              </div>
            </transition>
          </div>
        </div>
        <!--  -->
        <!--  -->
        <!--  -->
      </div>
    </section>
  </main>
</template>

<script>
import HeaderComponent from "../components/Header.vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import bgImage from "@/assets/images/w40k2poster.webp";
import { API_BASE_URL } from "../api/config";
import axios from "axios";

const platformImageMap = {
  xsxs: require("@/assets/images/MicrosoftXboxSeries.webp"),
  ps5: require("@/assets/images/SonyPlaystation5.webp"),
  ps4: require("@/assets/images/SonyPlaystation4.webp"),
  x1: require("@/assets/images/MicrosoftXboxOne.webp"),
  pc: require("@/assets/images/Windows.webp"),
  sw: require("@/assets/images/NintendoSwitch.webp"),
  // steamdeck: require("@/assets/images/Steam_Deck_colored_logo.webp"),
  macos: require("@/assets/images/mac-os.webp"),
  atarivcs: require("@/assets/images/1024px-Atari_VCS_logo.webp"),
  metaquest: require("@/assets/images/Meta_Quest_logo.webp"),
  visionpro: require("@/assets/images/Apple_Vision_Pro.webp"),
  ios: require("@/assets/images/iOS-Logo.webp"),
  android: require("@/assets/images/android-logo-2019.webp"),
  linux: require("@/assets/images/linux-operating-systems-logo.webp"),
};

export default {
  name: "GameCalendar",
  components: {
    HeaderComponent,
    FullCalendar,
  },
  data() {
    return {
      isLoading: true, // Initialize loading state
      newsletterEmail: "",
      acceptTerms: false,
      message: "", // Message state
      messageType: "", // Message type: 'error' or 'success'
      calendarOptions: {
        plugins: [dayGridPlugin, listPlugin],
        initialView: this.getCalendarView(window.innerWidth),
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,listMonth",
        },
        views: {
          listMonth: {
            titleFormat: { year: "numeric", month: "short" },
          },
        },
        displayEventTime: false,
        fixedWeekCount: false,
        height: "auto",
        eventDisplay: "block",
        // events: [],
        events: this.fetchGames,
        loading: this.handleLoading, // Use loading callback
        isOpen: false,
        eventDidMount: this.eventDidMount,
        eventsSet: this.handleEvents,
        datesSet: this.handleDateSet,
        eventClick: this.handleEventClick,
        eventClassNames: this.eventClassNames,
      },
      allEvents: [],
      bgImage,
      platformList: [
        { id: "xsxs", name: "XBox Series X|S" },
        { id: "ps5", name: "Playstation 5" },
        { id: "ps4", name: "Playstation 4" },
        { id: "x1", name: "XBox One" },
        { id: "pc", name: "PC" },
        { id: "sw", name: "Nintendo Switch" },
        // { id: "steamdeck", name: "Steam Deck" },
        { id: "macos", name: "Mac OS" },
        { id: "atarivcs", name: "Atari VCS" },
        { id: "metaquest", name: "Meta Quest" },
        { id: "visionpro", name: "Apple Vision Pro" },
        { id: "ios", name: "iOS" },
        { id: "android", name: "Android" },
        { id: "linux", name: "Linux" },
      ],
      platformFilters: {
        xsxs: true,
        ps5: true,
        ps4: true,
        x1: true,
        pc: true,
        sw: true,
        // steamdeck: false,
        macos: true,
        atarivcs: true,
        metaquest: true,
        visionpro: true,
        ios: true,
        android: true,
        linux: true,
      },
      genreList: [
        { id: "party", name: "Party" },
        { id: "rpg", name: "RPG" },
        { id: "fps", name: "FPS" },
        { id: "threeps", name: "Third-person Shooter" },
        { id: "tactical", name: "Tactical" },
        { id: "survival", name: "Survival" },
        { id: "survivalStratgey", name: "Survival Strategy" },
        { id: "actionRpg", name: "Action RPG" },
        { id: "musicRhythm", name: "Music/Rhythm" },
        { id: "educational", name: "Educational" },
        { id: "racing", name: "Racing" },
        { id: "platformer", name: "Platformer" },
        { id: "shooter", name: "Shooter" },
        { id: "fighting", name: "Fighting" },
        { id: "horror", name: "Horror" },
        { id: "idle", name: "Idle" },
        { id: "mmo", name: "MMO" },
        { id: "sports", name: "Sports" },
        { id: "strategy", name: "Strategy" },
        { id: "simulation", name: "Simulation" },
        { id: "puzzle", name: "Puzzle" },
        { id: "adventure", name: "Adventure" },
        { id: "action", name: "Action" },
        { id: "actionAdventure", name: "Action-Adventure" },
        { id: "fitness", name: "Fitness" },
        { id: "beatemup", name: "Beat 'em up" },
        { id: "casual", name: "Casual" },
      ],
      genreFilters: {
        party: true,
        rpg: true,
        fps: true,
        threeps: true,
        tactical: true,
        survival: true,
        survivalStratgey: true,
        actionRpg: true,
        musicRhythm: true,
        educational: true,
        racing: true,
        platformer: true,
        shooter: true,
        fighting: true,
        horror: true,
        idle: true,
        mmo: true,
        sports: true,
        strategy: true,
        simulation: true,
        puzzle: true,
        adventure: true,
        action: true,
        actionAdventure: true,
        fitness: true,
        beatemup: true,
        casual: true,
      },
    };
  },
  // watch: {
  //     filters: {
  //         handler() {
  //             this.applyFilters();
  //         },
  //         deep: true
  //     },
  //     'genreFilters': {
  //         handler() {
  //             this.applyFilters();
  //         },
  //         deep: true
  //     }
  // },
  computed: {
    buttonLabel() {
      return this.acceptTerms ? "Sign Up" : "Accept T&Cs";
    },
  },
  watch: {
    platformFilters: {
      handler() {
        this.applyFilters();
      },
      deep: true,
    },
    genreFilters: {
      handler() {
        this.applyFilters();
      },
      deep: true,
    },
  },
  created() {
    // this.genreList.forEach(genre => {
    //     this.genreFilters[genre.id] = true; // or false if you want them unchecked initially
    // });
    this.fetchGames();
  },
  // async created() {
  //   await this.fetchGames();
  // },
  mounted() {
    this.initCalendar();
    this.initEventListeners();
    window.addEventListener("resize", this.updateView);

    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.fullCalendar.getApi().updateSize();
      }, 200); // Adjust the delay as necessary
      this.applyStylesToLastEvents();
    });
  },
  beforeUnmount() {
    this.removeEventListeners();
    window.removeEventListener("resize", this.updateView);
  },
  methods: {
    toggleGenres() {
      const platformTagsWrapper = this.$refs.platformTagsWrapper;
      const rotateIcon = this.$refs.rotateIcon;

      if (!this.isOpen) {
        platformTagsWrapper.style.transition = "height 0.3s ease, opacity 0.2s ease 0.15s";
        platformTagsWrapper.style.height = "auto";
        platformTagsWrapper.style.opacity = "1";

        rotateIcon.style.transition = "transform 0.3s ease";
        rotateIcon.style.transform = "rotate(180deg)";
      } else {
        platformTagsWrapper.style.transition = "opacity 0.3s ease";
        platformTagsWrapper.style.opacity = "0";

        setTimeout(() => {
          platformTagsWrapper.style.transition = "height 0.3s ease 0.15s";
          platformTagsWrapper.style.height = "0px";
        }, 300);

        rotateIcon.style.transition = "transform 0.3s ease";
        rotateIcon.style.transform = "rotate(0deg)";
      }

      this.isOpen = !this.isOpen;
    },
    initEventListeners() {
      this.$refs.genreTitleWrapper.addEventListener("click", this.toggleGenres);
    },
    removeEventListeners() {
      this.$refs.genreTitleWrapper.removeEventListener("click", this.toggleGenres);
    },
    initCalendar() {
      const queryParams = this.$route.query;
      const calendarApi = this.$refs.fullCalendar.getApi();

      if (queryParams.month) {
        const [year, month] = queryParams.month.split("-").map(Number);
        const date = new Date(year, month - 1); // Adjust for zero-index month
        calendarApi.gotoDate(date);
      }

      // Set the initial view based on the window size right after initializing the date.
      const initialView = this.getCalendarView(window.innerWidth);
      calendarApi.changeView(initialView);
      this.currentView = initialView; // Store the current view state if needed
    },
    updateView() {
      const calendarApi = this.$refs.fullCalendar.getApi();
      const newView = this.getCalendarView(window.innerWidth);
      if (newView !== this.currentView) {
        calendarApi.changeView(newView);
        this.currentView = newView; // Update the current view state
      }
    },
    getCalendarView(width) {
      return width < 768 ? "listMonth" : "dayGridMonth";
    },
    handleEvents(events) {
      this.currentEvents = events;
      this.adjustColumnWidths(events);

      this.$nextTick(() => {
        this.applyStylesToLastEvents();
      });
    },
    handleLoading(isLoading) {
      this.isLoading = isLoading;
    },
    async fetchGames() {
      // For testing, keep isLoading true indefinitely
      this.isLoading = true;
      console.log("Loading state maintained for testing");

      try {
        const response = await fetch(`${API_BASE_URL}/api/games`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();

        const games = result.data.games;

        this.allEvents = games.map((game) => ({
          title: game.name,
          start: game.releaseDate,
          url: `/game/${game.slug}`,
          extendedProps: {
            slug: game.slug,
            description: game.description,
            // image: game.image,
            image: game.imageUrls.publicImage,
            website: game.website,
            publisher: game.publisher,
            releaseTrailer: game.releaseTrailer,
            genres: game.genres,
            platforms: game.platforms.split(";"),
            budget: game.budget,
          },
        }));
        this.applyFilters();
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    },
    applyFilters() {
      const filteredEvents = this.allEvents.filter((event) => {
        // Check if any of the selected platforms is in the event's platforms list
        const platformMatch = event.extendedProps.platforms.some((platform) => this.platformFilters[platform]);

        // Check if any of the selected genres is in the event's genres list
        const genreMatch = event.extendedProps.genres.some((genre) => this.genreFilters[genre.toLowerCase()]); // Ensure your genre IDs match the genre text

        return platformMatch && genreMatch; // Both must match to include the event
      });

      this.calendarOptions.events = filteredEvents;
    },
    eventDidMount(info) {
      const eventImage = info.event.extendedProps.image;
      if (eventImage) {
        info.el.style.backgroundImage = `url(${eventImage})`;
        info.el.style.backgroundSize = "cover";
        info.el.style.backgroundPosition = "top left";
        info.el.style.color = "white"; // Adjust text color for readability
        info.el.style.height = "100%";
      }

      const eventPlatforms = info.event.extendedProps.platforms;
      if (eventPlatforms) {
        const platformContainer = document.createElement("div");
        platformContainer.className = "fc-event-platforms";

        eventPlatforms.forEach((platform) => {
          const platformImg = document.createElement("img");
          platformImg.className = "fc-event-platform-img";
          platformImg.src = platformImageMap[platform];
          platformImg.alt = platform;
          platformImg.style.width = "auto"; // Adjust width as needed
          platformImg.style.height = "1.2em"; // Maintain aspect ratio
          platformImg.style.marginRight = ".375em"; // Adjust margin as needed
          platformImg.style.padding = ".25em .375em"; // Add padding
          platformImg.style.borderRadius = ".375em";
          platformImg.style.backgroundColor = "var(--color--white)"; // Add background color for better readability
          platformImg.style.fontSize = "1em";
          platformContainer.appendChild(platformImg);
        });

        const titleContainer = info.el.querySelector(".fc-event-title-container");
        const listTitleContainer = info.el.querySelector(".fc-list-event-title");
        if (titleContainer) {
          titleContainer.appendChild(platformContainer);
        } else if (listTitleContainer) {
          listTitleContainer.appendChild(platformContainer);
        }
      }
    },
    handleEventClick(clickInfo) {
      console.log("Event clicked, slug:", clickInfo.event.extendedProps.slug);

      // Store the current calendar URL in localStorage
      const currentURL = this.$route.fullPath;
      localStorage.setItem("lastCalendarPage", currentURL);

      clickInfo.jsEvent.preventDefault(); // Stop the default navigation
      this.$router.push({
        name: "GameDetails",
        params: {
          slug: clickInfo.event.extendedProps.slug,
        },
      });
    },
    adjustColumnWidths(events) {
      const calendarApi = this.$refs.fullCalendar.getApi();
      const view = calendarApi.view;
      const startDate = view.activeStart;
      const endDate = view.activeEnd;

      const days = [
        { day: 0, class: "fc-day-sun", hasEvents: false },
        { day: 1, class: "fc-day-mon", hasEvents: false },
        { day: 2, class: "fc-day-tue", hasEvents: false },
        { day: 3, class: "fc-day-wed", hasEvents: false },
        { day: 4, class: "fc-day-thu", hasEvents: false },
        { day: 5, class: "fc-day-fri", hasEvents: false },
        { day: 6, class: "fc-day-sat", hasEvents: false },
      ];

      events.forEach((event) => {
        const eventDate = new Date(event.start);
        if (eventDate >= startDate && eventDate < endDate) {
          const eventDay = eventDate.getDay();
          days.forEach((day) => {
            if (eventDay === day.day) {
              day.hasEvents = true;
            }
          });
        }
      });

      document.querySelectorAll(".fc-col-header-cell, .fc-daygrid-day").forEach((cell) => {
        cell.classList.remove("narrow");
      });

      days.forEach((day) => {
        if (!day.hasEvents) {
          document
            .querySelectorAll(`.fc-col-header-cell.${day.class}, .fc-daygrid-day.${day.class}`)
            .forEach((cell) => {
              cell.classList.add("narrow");
            });
        }
      });
    },
    // handleDateSet(arg) {
    //     const viewStart = arg.view.currentStart;
    //     const month = viewStart.getMonth() + 1; // JavaScript months are zero-indexed
    //     const year = viewStart.getFullYear();
    //     this.$router.replace({ query: { ...this.$route.query, month: `${year}-${month}` } });
    // }
    handleDateSet(arg) {
      const currentViewStart = arg.view.currentStart;
      const currentMonth = currentViewStart.getMonth() + 1; // JavaScript months are zero-indexed
      const currentYear = currentViewStart.getFullYear();

      // Get today's date for comparison
      const today = new Date();
      const thisMonth = today.getMonth() + 1;
      const thisYear = today.getFullYear();

      this.$nextTick(() => {
        // Update the URL based on the current month
        if (currentMonth !== thisMonth || currentYear !== thisYear) {
          this.$router
            .replace({ query: { ...this.$route.query, month: `${currentYear}-${currentMonth}` } })
            .then(() => {
              const currentURL = this.$route.fullPath;
              console.log("Storing current calendar page URL:", currentURL); // Debugging line
              localStorage.setItem("lastCalendarPage", currentURL);
            });
        } else {
          const newQuery = { ...this.$route.query };
          delete newQuery.month;
          this.$router.replace({ query: newQuery }).then(() => {
            const currentURL = this.$route.fullPath;
            console.log("Storing current calendar page URL:", currentURL); // Debugging line
            localStorage.setItem("lastCalendarPage", currentURL);
          });
        }
      });
    },
    // applyStylesToLastEvents() {
    //     const listDays = document.querySelectorAll('.fc-list-day');
    //     listDays.forEach(day => {
    //         let nextSibling = day.nextElementSibling;
    //         let lastEvent = null;
    //         while (nextSibling && nextSibling.classList.contains('fc-list-event')) {
    //             lastEvent = nextSibling;
    //             nextSibling = nextSibling.nextElementSibling;
    //         }
    //         if (lastEvent) {
    //             lastEvent.style.borderBottom = '1px solid white';
    //             lastEvent.style.borderBottomLeftRadius = '6px';
    //             lastEvent.style.borderBottomRightRadius = '6px';
    //         }
    //     });
    // }
    applyStylesToLastEvents() {
      const listDays = document.querySelectorAll(".fc-list-day");
      listDays.forEach((day, index) => {
        let nextSibling = day.nextElementSibling;
        let lastEvent = null;
        while (nextSibling && nextSibling.classList.contains("fc-list-event")) {
          lastEvent = nextSibling;
          nextSibling = nextSibling.nextElementSibling;
        }
        if (lastEvent) {
          const uniqueClass = `last-event-${index}`;
          lastEvent.classList.add(uniqueClass);

          // Apply styles directly to the last event's table cells
          lastEvent.querySelectorAll("td").forEach((td) => {
            td.style.borderBottomLeftRadius = "6px";
            td.style.borderBottomRightRadius = "6px";
            td.style.overflow = "hidden";
            td.style.boxShadow = `
                inset 0 -1px 0 0 white,   /* Bottom border */
                inset 1px 0 0 0 white,    /* Right border */
                inset -1px 0 0 0 white    /* Left border */
            `;
          });

          // Apply styles to the ::before pseudo-element of the last event
          let styleElement = document.getElementById("fc-event-before-style");
          if (!styleElement) {
            styleElement = document.createElement("style");
            styleElement.id = "fc-event-before-style";
            document.head.appendChild(styleElement);
          }
          styleElement.innerHTML += `
                        .${uniqueClass}::before {
                            border-bottom: 1px solid white;
                            border-bottom-left-radius: 6px;
                            border-bottom-right-radius: 6px;


                        }
                    `;
        }
      });
    },
    async submitNewsletter() {
      if (!this.acceptTerms) {
        this.setMessage("You must accept the terms and conditions.", "error");
        return;
      }
      try {
        const response = await axios.post(
          "https://connect.mailerlite.com/api/subscribers",
          {
            email: this.newsletterEmail,
            groups: ["131622667697522131"],
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiOGEwYTgxMDY3YWE5YmRkZDg1NmJhNDhjMWU2ZTUyMDZlNmJjODJkYjlmNWQxNzZiMTkxZGM1NTAyYmM5MWNhY2U0M2RjMjhiODE1YzNjNDEiLCJpYXQiOjE3MjU1MjU0NDMuOTE0NzY4LCJuYmYiOjE3MjU1MjU0NDMuOTE0NzczLCJleHAiOjQ4ODExOTkwNDMuOTA3OTA3LCJzdWIiOiIxMDg2Mzk4Iiwic2NvcGVzIjpbXX0.MtFawkF7_PLhUa1M5LQLnUVA6FXx0Z-WnxmKpSy8HAfMVTq2X1Ne4osWFyikbFEReqKmm5uAzzey0k8auaTm-5hesiekQ3wYz0sS1hD_--qlmywRWICSp7rJdSnz3V1F9qqq0_iqB24awnIoaDAzaldEmfojcJSKL_99TosXL-hWxkMfoMLpqdyrDAsHFO9JxDnqy-10evC_a9Um2J0AURMfI_gryByLaLqUCADF9ITm7wRfYKitA0v6eJbMCHYmt53cMKErRkueV_SFq7o951oGeL9yk4MdkwO0FH1Xlkpxk3xaJmWA5dOAI5Pi3zinIFxto1l8CvAFfk2eF7D5ljs9L_p7B9ZbqjoL-EyRC5mjK-74ciMV0KzgQ1vbHO2dki6Krc0EEKvuJO14172dQCZ2Am_cT9AHgIeE4e7PvX0XekiF4G-vQAWX8017VXTp-LK2yex3IdBBZ-dRc8vl4Qqe3kwRovQJuquRGYtkRnBQLT1hqtoUbv3c_o0S66QJ8JFobUbf_apkDiPQrfQcWtZ1XeKSEoFXxw5OBCOO4VeS0nXfPS86-p0YuymIP0C1-5o2tByjxBR2cAycuwg58bZ-J8zomkeUTywwizjrVGv6k5uZpqiF7iWiYkZOot05ba6Nuch79TKeb5D66dxuXF86ihkcJKqbKyx9tCXMoQo",
            },
          }
        );

        this.setMessage(response.data.message || "You have successfully signed up for the newsletter!", "success");
        this.newsletterEmail = ""; // Clear the email input after success
        this.acceptTerms = false; // Reset the checkbox
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          // If MailerLite API returns an error message (e.g. email already exists)
          this.setMessage(error.response.data.message, "error");
        } else {
          // Fallback to a generic error message
          this.setMessage("Failed to sign up for the newsletter. Please try again later.", "error");
        }
      }
    },
    setMessage(msg, type) {
      this.message = msg;
      this.messageType = type;

      // Clear the message after 5 seconds
      setTimeout(() => {
        this.message = "";
      }, 5000);
    },
  },
};
</script>

<style scoped>
.platform-checkbox-wrapper {
  position: relative;
}

.platform-checkbox-wrapper div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.5em 0.625em;
  transition: background-color 0.3s ease, opacity 0.3s ease;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0.25em;
  font-family: "Homevideo Blg 6 G", sans-serif; /* Ensure you have this font loaded */
  color: black;
  font-size: 0.75em;
  line-height: 1;
}

.platform-checkbox-wrapper input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

input[type="checkbox"]:checked ~ div {
  background-color: rgba(255, 255, 255, 1);
}

.rotate-icon {
  transform-origin: center;
}
</style>

<style>
.fc-theme-standard .fc-scrollgrid {
  border: none;
}

.fc th {
  font-weight: 400;
}

/* Calendar Navigation */
.fc .fc-toolbar-title {
  font-size: 2em;
  margin: 0px;
}

@media screen and (max-width: 767px) {
  /* .fc-dayGridMonth-button {
    display: none !important;
  }
  .fc-listMonth-button {
    border-radius: 0.25em !important;
  }*/
  /* .fc-dayGridMonth-button,
  .fc-listMonth-button {
    display: none !important;
  } */
  .fc-header-toolbar .fc-toolbar-chunk:last-child {
    display: none !important;
  }
  .fc-header-toolbar {
    display: flex;
    justify-content: space-between; /* Ensure space between the elements */
    flex-direction: row-reverse; /* Swap the first two elements */
  }
}

/* .fc-prev-button,
.fc-next-button {
    background-color: transparent !important;
    color: white !important;
    border: 0 !important;
    font-size: 1em !important;
} */

.fc .fc-button-primary {
  background-color: white;
  border-color: white;
  color: black;
}

.fc .fc-button-primary:hover {
  background-color: rgb(255 255 255 / 75%);
  border-color: #ffffff;
  color: #000;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:disabled {
  background-color: rgb(255 255 255 / 0%);
  border-color: #ffffff;
  color: white;
}

.fc .fc-button {
  font-size: 0.75em;
}

/* Calendar Styling */

/* .fc-day-other > div {
    display:none;
} */

.fc .fc-daygrid-day-top {
  position: relative;
  z-index: 999;
}

.fc-event {
  min-height: 8em;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  padding: 0.5em;
}

.fc-event-main {
  margin-top: auto;
}

.fc-h-event {
  border: none;
  /* display: block; */
}

.fc-daygrid-event {
  white-space: normal !important;
  /* align-items: normal !important;.fc-event */
  margin-top: 0px !important;
  border-radius: 0px;
}

.fc .fc-daygrid-day-events {
  margin-top: 0px;
  border: 1px solid white;
  border-radius: 6px;
  overflow: hidden;
  min-height: 3em !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: none;
  padding: 2px;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 0em;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 0px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 0px;
}

.fc .fc-scrollgrid-section-sticky > * {
  background: transparent;
  position: static;
}

.fc-daygrid-day-frame {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: rgb(255 255 255 / 50%);
  border-radius: 6px;
}

.fc-daygrid-day-events {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.fc-daygrid-event-harness {
  flex-grow: 1;
  margin: 0 !important; /* Ensure no margin disrupts the layout */
}

.fc-col-header-cell-cushion {
  background-color: #fff;
  border-radius: 6px;
}

.fc-event::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%); */
  pointer-events: none; /* Ensures the overlay doesn't interfere with clicking the event */
}
.fc-event {
  position: relative;
  overflow: hidden; /* Ensure the pseudo-element is contained within the .fc-event */
}

.fc-event-platforms {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  row-gap: 0.375em;
}

.fc-daygrid-event::before {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%);
}

.fc .fc-daygrid-day-number {
  position: absolute;
  /* z-index: 99; */
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  font-size: 0.75em;
  font-weight: bold;
  transform: rotate(-45deg);
  padding-left: 3px;
}

.fc .fc-daygrid-day-number::before {
  content: "";
  position: absolute;
  top: 3px;
  left: -6px;
  width: 0;
  height: 0;
  border-top-left-radius: 6px;
  border-style: solid;
  border-width: 30px 30px 0 0; /* Triangle pointing to top left */
  border-color: rgb(255, 255, 255) transparent transparent transparent; /* Triangle color */
  z-index: -1;
  transform: rotate(45deg);
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}

.fc-day-today .fc-daygrid-day-frame {
  background-color: rgb(195 255 120);
}

.fc-day-today.fc-day-other .fc-daygrid-day-frame {
  background-color: rgb(147 170 125);
}

.fc-day-today .fc-daygrid-day-events {
  border: 1px solid rgb(125 225 0);
}

.fc-day-today .fc-daygrid-day-number::before {
  border-color: rgb(125, 225, 0) transparent transparent transparent; /* Triangle color */
}

.fc-day-today.fc-day-other .fc-daygrid-day-events {
  opacity: 1;
}

.fc-day-today .fc-daygrid-day-top {
  opacity: 1 !important;
}

.fc-day-other .fc-daygrid-day-events {
  opacity: 0.3;
}

.fc-h-event .fc-event-title {
  font-weight: 500;
  font-family: Avenirnextcyr, sans-serif;
  color: var(--color--white);
  font-size: 1.25em;
  line-height: 1.4;
}

/* Initial narrower width for Monday and Sunday */
.narrow {
  width: 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
}

.fc-toolbar-chunk > .w-dyn-item {
  display: none;
}

/* LIST VIEW */

.fc-theme-standard .fc-list {
  border: none;
}

.fc .fc-list-sticky .fc-list-day > * {
  background: transparent;
  position: relative;
  top: 0px;
  padding-top: 1em;
}

.fc-theme-standard .fc-list-day-cushion {
  background-color: white;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.fc .fc-list-event {
  cursor: pointer;
  height: 100px !important;
  display: revert-layer;
}

.fc td {
  vertical-align: bottom !important;
}

.fc .fc-list-event-dot {
  display: none;
}

td.fc-list-event-graphic {
  display: none;
}

.fc-list-table .fc-list-event:hover td {
  background-color: transparent;
}

.fc .fc-list-table {
  border-top-style: hidden;
  border-bottom-style: hidden;
  border-left-style: hidden;
  border-right-style: hidden;
}

.fc .fc-list-event-title {
  position: relative;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 50%, transparent);
  -webkit-box-shadow: inset 1px 0 0 0 white, /* Left border */ inset -1px 0 0 0 white; /* Right border */
  -moz-box-shadow: inset 1px 0 0 0 white, /* Left border */ inset -1px 0 0 0 white; /* Right border */
  box-shadow: inset 1px 0 0 0 white, /* Left border */ inset -1px 0 0 0 white; /* Right border */
}

.fc .fc-list-event-title a {
  font-size: 1.25em;
  line-height: 1.4;
}

.fc .fc-list-event-title a:hover {
  color: inherit;
  text-decoration: none !important;
}

.fc-list-day.fc-day-today > th > .fc-list-day-cushion {
  background-color: rgb(125 225 0);
}

.fc-event-today > .fc-list-event-title {
  -webkit-box-shadow: inset 1px 0 0 0 rgb(125 225 0), /* Left border */ inset -1px 0 0 0 rgb(125 225 0),
    /* Right border */ inset 0 -1px 0 0 rgb(125 225 0) !important; /* Bottom border */
  -moz-box-shadow: inset 1px 0 0 0 rgb(125 225 0), inset -1px 0 0 0 rgb(125 225 0), inset 0 -1px 0 0 rgb(125 225 0) !important; /* Bottom border */
  box-shadow: inset 1px 0 0 0 rgb(125 225 0), inset -1px 0 0 0 rgb(125 225 0), inset 0 -1px 0 0 rgb(125 225 0) !important; /* Bottom border */
}

/* .fc .fc-list-event::before {
  border-left: solid 1px white;
  border-right: solid 1px white;
} */

/* 
*
* Loading Element
*
*/
.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 300px; Adjust as needed */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 4px solid #000;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-overlay p {
  margin-top: 1rem;
  color: #000;
}

/* New loading styling */
.calendar-container {
  position: relative;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #c3ff78;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  border-radius: 6px;
}
</style>
